<template>
  <v-container >
    <v-row justify="center">
      <h1 class="page-title my-10">{{ $t("course-menu") }}</h1>
    </v-row>
    <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
      <v-timeline-item
        v-for="(item, i) in events"
        :key="i"
        :color="getColor(item)"
        :icon="item.icon"
        fill-dot
      >
        <v-card :color="getColor(item)" dark>
          <v-card-title class="title">
            {{ $t(item.title) }} ({{ item.duration }}
            {{ $t(item.durationType) }})
          </v-card-title>
          <v-card-subtitle><v-icon>mdi-calendar</v-icon> {{item.initDate}} - {{item.finalDate}}</v-card-subtitle>
          <v-card-text class="white pt-2 text--primary">
            <p>
              {{$t(item.description)}}
            </p>
          
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "Course",
  data() {
    return {
      events: [
        {
          title: "course-human-rights-education",
          description: "course-human-rights-education-desc",
          duration: "1",
          durationType: "day",
          icon: "mdi-star",
          initDate:"2021-04-15",
          finalDate:"2021-04-15",
          
        },
        {
          title: "course-international-humanitarian-rights",
          description: "course-international-humanitarian-rights-desc",
          duration: "1",
          durationType: "day",
          icon: "mdi-book-variant",
          initDate:"2021-04-22",
          finalDate:"2021-04-22",
        },
        {
          title: "course-globalized-citizenship-for-sustainability",
          description: "course-globalized-citizenship-for-sustainability-desc",
          duration: "1",
          durationType: "day",
          icon: "mdi-airballoon",
          initDate:"2021-04-29",
          finalDate:"2021-04-29",
        },
        {
          title: "course-migration-and-refugee-processes",
          description: "course-migration-and-refugee-processes-desc",
          duration: "1",
          durationType: "day",
          icon: "mdi-buffer",
          initDate:"2021-05-06",
          finalDate:"2021-05-06",
        },
        {
          title: "course-gender-identity",
          description: "course-gender-identity-desc",
          duration: "1",
          durationType: "day",
          icon: "mdi-book-variant",
          initDate:"2021-05-13",
          finalDate:"2021-05-13",
        },
        {
          title: "course-racism",
          description: "course-racism-desc",
          duration: "1",
          durationType: "day",
          icon: "mdi-airballoon",
          initDate:"2021-05-20",
          finalDate:"2021-05-20",
        },
        {
          title: "course-citizenship-Environment-and-Sustainability",
          description: "course-citizenship-Environment-and-Sustainability-desc",
          duration: "1",
          durationType: "day",
          icon: "mdi-buffer",
          initDate:"2021-05-27",
          finalDate:"2021-05-27",
        },
      ],
    };
  },
  methods:{
    getColor(item){      
        return item.initDate<=dayjs(Date.now()).format('YYYY-MM-DD')? "secondary":"primary";
    }
  }
};
</script>

<style></style>
