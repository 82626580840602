<template>
  <v-app>
      <v-app-bar
        :height="appBarHeigth"
        app
        color="primary"
        elevate-on-scroll
        dark
      >
        <v-app-bar-nav-icon  v-if="$vuetify.breakpoint.xsOnly"
          class="mr-2"
          @click="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <v-tabs
          centered          
          class="hidden-sm-and-down"
          slider-color="white"
          color="white"
        >
          <v-tab   v-for="item in items" :key="item.title" :to="item.url">{{
            $t(item.title)
          }}</v-tab>
        </v-tabs>

        <SwitchLanguage />
      </v-app-bar>
      <v-navigation-drawer
        app
        dark
        v-model="drawer"
        temporary
        :color="color"
        v-if="$vuetify.breakpoint.xsOnly && drawer"
      >
        <v-list dense nav class="py-5">
          <v-list-item two-line :class="miniVariant && 'px-0'">
            <v-list-item-content>
              <div class="d-flex align-center">
                <v-img
                  alt="ClassRoom Libertadores"
                  class="shrink ml-4"
                  contain
                  src=""
                  transition="scale-transition"
                  width="200"
                />
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <div class="py-5">
            <div v-for="item in items" :key="item.title">
              <v-list-group
                v-if="item.childrens.length > 0"
                active-class="active-class"
                link
                :prepend-icon="item.icon"
              >
                <template v-slot:activator>
                  <v-list-item-title class="v-list-item__title">{{
                    $t(item.title)
                  }}</v-list-item-title>
                </template>
                <div v-for="child in item.childrens" :key="child.title">
                  <v-list-item
                    class="ml-10 v-list-item__title"
                    dark
                    :to="child.url"
                  >
                    <v-list-item-icon class="active-class">
                      <v-icon>{{ child.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title
                      class="active-class"
                      v-text="$t(child.title)"
                    ></v-list-item-title>
                  </v-list-item>
                </div>
              </v-list-group>
              <v-list-item v-if="item.childrens.length <= 0" :to="item.url">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        
          <router-view></router-view>
      
      </v-main>
      <v-footer
      color="primary"
        class="font-weight-medium"
        v-if="!$vuetify.breakpoint.xsOnly"
        absolute
        app
      >
        <v-col class="text-center footer-text-class" cols="12">
          <span class="white--text">
            Conexion Sur-Sur {{ new Date().getFullYear() }}
          </span>
        </v-col>
      </v-footer>
  </v-app>
</template>

<script>
import SwitchLanguage from "@/components/BasicComponents/SwitchLanguage";
export default {
  name: "App",

  components: {
    SwitchLanguage,
  },

  data() {
    return {
      drawer: false,
      expandOnHover: false,
      miniVariant: true,
      items: [
        {
          title: "dashboard-menu",
          icon: "mdi-home",
          url: "/home",
          childrens: [],
        },
        {
          title: "objectives-menu",
          icon: "mdi-format-list-checkbox",
          url: "/objectives",
          childrens: [],
          open: false,
        },
        {
          title: "corporations-menu",
          icon: "mdi-domain",
          url: "/corporations",
          childrens: [],
          open: false,
        },
        {
          title: "team-menu",
          icon: "mdi-account-group",
          url: "/team",
          childrens: [],
          open: false,
        },
        {
          title: "course-menu",
          icon: "mdi-calendar-check",
          url: "/course",
          childrens: [],
          open: false,
        },
        // {
        //   title: "experiences-menu",
        //   icon: "mdi-format-quote-open",
        //   url: "/experiences",
        //   childrens: [],
        //   open: false,
        // },
        // {
        //   title: "contact-menu",
        //   icon: "mdi-mailbox",
        //   url: "/contactus",
        //   childrens: [],
        //   open: false,
        // },
      ],
      items_admin: [],
      color: "primary",
      colors: ["primary", "blue", "success", "red", "teal"],
      permanent: true,
      drawerOpen: false,
      appBarHeigth: 85,
    };
  },
};
</script>
<style lang="scss">
@import "@/scss/variables.scss";
@import "@/scss/app/custom.scss";
</style>
