<template>
  <div>
    <section class="mb-12">
      <v-img
        src="@/assets/images/sur-sur_ban.jpg"
        
        width="100%"
      />
      <v-row justify="center" align="end">
        <v-btn fab elevation="13" @click="scrollMeTo('whatIs')" color="primary"
          ><v-icon>mdi-arrow-down</v-icon></v-btn
        >
      </v-row>
    </section>

    <section ref="whatIs" class="my-12">
      <v-container>
        <v-row>
          <v-col cols="12" class="px-10 pt-2">
            <h1
              class="text-center primary--text my-10"
              v-html="$t('home-what-is')"
            ></h1>
            <p class="text-center" v-html="$t('home-what-is-pharagraph')"></p
          ></v-col>
        </v-row>
      </v-container>
    </section>
    <section class="section-gray my-12">
      <v-container>
        <v-row justify="center">
          <v-col cols="12" class="px-10 pt-2">
            <h1
              class="text-center white--text my-10"
              v-html="$t('home-why-participate')"
            ></h1>
            <p
              class="text-center white--text"
              v-html="$t('home-why-participate-pharagraph')"
            ></p>
            <v-card class="mx-auto my-12" max-width="800" tile>
              <v-list elevation="4" rounded>
                <v-list-item
                  v-for="reason in whyParticipate"
                  :key="reason.description"
                >
                  <v-list-item-avatar>
                    <v-icon
                      color="white"
                      class="aux-color-backgroud lighten-1"
                      dark
                    >
                      {{ reason.icon }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="$t(reason.description)"
                    ></v-list-item-title>
                  </v-list-item-content> </v-list-item
              ></v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section>
      <v-container>
        <v-row justify="center">
          <h1
            class="text-center primary--text my-10"
            v-html="$t('home-how-work-title')"
          ></h1>
        </v-row>
        <v-row justify="center">
          <v-col cols="8">
            <p class="text-center" v-html="$t('home-how-work-pharagraph')"></p>
          </v-col>
        </v-row>
        <v-row justify="center" class="pb-10">
          <v-col cols="6">
            <v-expansion-panels inset>
              <v-expansion-panel v-for="(item, i) in howWorkSlides" :key="i">
                <v-expansion-panel-header
                  color="primary"
                  class="text-center white--text strong--text obscure-backgroud"
                  >{{ $t(item.title) }}</v-expansion-panel-header
                >
                <v-expansion-panel-content class="strong--text py-3">
                  {{ $t(item.description) }}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section>
      <v-container>
        <v-row>
          <v-col
            v-for="(university, i) in universities"
            :key="i"
            cols="4"
            class="px-10 pt-10"
            ><v-img
              class="shrink ml-4  img-universities"
              :src="university.logo"
              contain
              transition="scale-transition"
              height="100"
              @click="OpenModal(university)"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="800"
      v-model="universityShow"
    >
      <v-card>
        <v-toolbar
          class="text-h5 px-8 py-5"
          color="primary"
          dark
          v-html="$t(currentUniversity.name)"
        ></v-toolbar>
        <v-card-text>
          <v-img
            class="mt-10 mx-5"
            contain
            :src="currentUniversity.logo"
            transition="scale-transition"
            height="100"
            @click="OpenModal(currentUniversity)"
          />
          <div
            class=" py-5 px-5"
            v-html="$t(currentUniversity.description)"
          ></div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="universityShow = false">{{
            $t("close-button")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
  data() {
    return {
      universityShow: false,
      currentUniversity: {},
      howWorkSlides: [
        {
          title: "home-how-work-icebreaker-title",
          description: "home-how-work-icebreaker-pharagraph",
        },
        {
          title: "home-how-work-develop-collaborative-activities-title",
          description:
            "home-how-work-develop-collaborative-activities-pharagraph",
        },
        {
          title: "home-how-work-case-study-title",
          description: "home-how-work-case-study-pharagraph",
        },
        {
          title: "home-how-work-final-reflection-title",
          description: "home-how-work-final-reflection-pharagraph",
        },
      ],
      whyParticipate: [
        {
          icon: "mdi-wallet-travel",
          description: "home-why-participate-1",
        },
        {
          icon: "mdi-book-open",
          description: "home-why-participate-2",
        },
        {
          icon: "mdi-translate",
          description: "home-why-participate-3",
        },
        {
          icon: "mdi-web",
          description: "home-why-participate-4",
        },
      ],
      universities: [
        {
          name: "university-unesp-name",
          location: "Brasil",
          logo: require('@/assets/images/corporations/unesp/logo-unesp.jpg'),
          websiteUrl: "https://www2.unesp.br/",
          description: "university-unesp-description",
        },
        {
          name: "university-libertadores-name",
          location: "Colombia",
          logo: require('@/assets/images/corporations/libertadores/logo_libertadores.png'),
          websiteUrl: "https://www.ulibertadores.edu.co/",
          description: "university-libertadores-description",
        },
        {
          name: "university-simon-name",
          location: "Colombia",
          logo: require('@/assets/images/corporations/simonbolivar/logo_original-ciudad.png'),
          websiteUrl: "https://www.unisimon.edu.co/",
          description: "university-simon-description",
        },
      ],
    };
  },
  methods: {
    OpenModal(university) {
      this.universityShow = true;
      this.currentUniversity = university;
    },
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
  },
};
</script>
