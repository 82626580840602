<template>
  <v-container fluid>
    <v-row justify="center">
      <h1 class="page-title my-10">{{ $t("corporations-menu") }}</h1>
    </v-row>
    <v-row justify="center" dense>
      <v-col v-for="(university, i) in universities" :key="i" cols="8">
        <v-card color="primary">
          <v-carousel cycle height="400">
            <v-carousel-item
              v-for="(item, i) in university.imageGallery"
              :key="i"
              :src="item"
              reverse-transition="fade-transition"
              transition="fade-transition"
            ></v-carousel-item>
          </v-carousel>        
          <v-card-actions>
            <v-card-title
              class="white--text mx-4"
              v-html="$t(university.name)"
            ></v-card-title>

            <v-spacer></v-spacer>
            <a icon :href="university.websiteUrl">
              <v-icon color="white">mdi-web</v-icon>
            </a>
            <v-btn
              color="white"
              @click="university.show = !university.show"
              icon
            >
              <v-icon>mdi-text</v-icon>
            </v-btn>
          </v-card-actions>
          <v-cart-text v-if="university.show">
            <p class="text-justify mx-10 white--text">
              {{ $t(university.description) }}
            </p>
          </v-cart-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Corporations",
  data() {
    return {
      universities: [
        {
          name: "university-unesp-name",
          location: "Brasil",
          logo: require("@/assets/images/corporations/unesp/logo-unesp.jpg"),
          imageGallery: [
            require("@/assets/images/corporations/unesp/01.jpg"),
            require("@/assets/images/corporations/unesp/33920.jpg"),
            require("@/assets/images/corporations/unesp/20191009_180627.jpg"),
            require("@/assets/images/corporations/unesp/fcf_unesp.jpg"),
            require("@/assets/images/corporations/unesp/fcl_assis_2.jpg"),
            require("@/assets/images/corporations/unesp/microscopia1.jpg"),
            require("@/assets/images/corporations/unesp/unesp-doacao.jpeg"),
            require("@/assets/images/corporations/unesp/unnamed.jpg"),
          ],
          websiteUrl: "https://www2.unesp.br/",
          description: "university-unesp-description",
          show: false,
        },
        {
          name: "university-libertadores-name",
          location: "Colombia",
          logo: require("@/assets/images/corporations/libertadores/logo_libertadores.png"),
          imageGallery: [
            require("@/assets/images/corporations/libertadores/libertadoresbackground.jpg"),
            require("@/assets/images/corporations/libertadores/CARTAGENA.jpg"),
            require("@/assets/images/corporations/libertadores/FULL.jpg"),
            require("@/assets/images/corporations/libertadores/ULIB.jpg"),
          ],
          websiteUrl: "https://www.ulibertadores.edu.co/",
          description: "university-libertadores-description",
          show: false,
        },
        {
          name: "university-simon-name",
          location: "Colombia",
          logo: require("@/assets/images/corporations/simonbolivar/logo_original-ciudad.png"),
          imageGallery: [
            require("@/assets/images/corporations/simonbolivar/universidad_simon_bolivar.jpg"),
            require("@/assets/images/corporations/simonbolivar/USB.jpg"),
            require("@/assets/images/corporations/simonbolivar/USB2.jpg"),
          ],
          websiteUrl: "https://www.unisimon.edu.co/",
          description: "university-simon-description",
          show: false,
        },
      ],
    };
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style></style>
