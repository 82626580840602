<template>
  <v-container>
    <v-row justify="center">
      <h1 class="page-title my-10">{{ $t("team-menu") }}</h1>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <h1
          class="text-center uppercase-text primary--text my-10"
          v-html="$t('teacher-title')"
        ></h1>
      </v-col>
      <v-col cols="4" v-for="(teacher, i) in teachers" :key="i">
        <v-card class="mx-auto white" max-width="434" tile>
          <v-img height="100%"  :src="teacher.backgroundImage" >
            <v-row align="end" class="fill-height">
              <v-col align-self="start" class="pl-5" cols="12">
                <v-avatar  color="grey" size="100" >
                  <v-img :src="teacher.profilePicture"></v-img>
                </v-avatar>
              </v-col>
              <v-col class="py-0">
                <v-list-item color="rgba(0, 0, 0, .4)" dark>
                  <v-list-item-content>
                    <v-list-item-title class="title">
                      {{ teacher.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-html="$t(teacher.university)"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-img>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="showTeacher(teacher)" icon>
              <v-icon>mdi-text</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card></v-col
      >
      <v-col v-if="visibleTeacher" cols="12">
        <transition name="fade" mode="out-in" appear>
          <v-card color="primary" class="px-6">
            <v-card-actions class="ml-4">
               <v-avatar  color="grey" size="100" >
                  <v-img :src="teacherToShow  .profilePicture"></v-img>
                </v-avatar>
              <v-card-title class="white--text stronged--text"> {{ teacherToShow.name }}</v-card-title>
              <v-spacer></v-spacer>
              <v-btn color="white" @click="closeTeacher()" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-actions>
            <v-card-text class="mx-2 white--text text-justify stronged--text">{{ $t(teacherToShow.role) }}</v-card-text>
          </v-card>
        </transition>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <h1
          class="text-center uppercase-text primary--text my-10"
          v-html="$t('administrative-personal')"
        ></h1> </v-col
      ><v-col cols="8">
        <v-list three-line>
          <v-row>
            <template v-for="(personal, index) in administrativePersonal">
              <v-col cols="4" :key="index">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon
                      color="white"
                      class="aux-color-backgroud lighten-1"
                      dark
                    >
                      mdi-account
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      v-html="personal.name"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-html="personal.university"
                    ></v-list-item-subtitle>
                  </v-list-item-content> </v-list-item
              ></v-col>
            </template>
          </v-row>
        </v-list> </v-col
    ></v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Team",
  components: {},
  data() {
    return {
      teachers: [
        {
          name: "Celia Rossi ",
          role: "teacher-celia-role",
          university: "university-unesp-name",
          profilePicture: require("@/assets/images/profiles/celiarossi.jpg"),
          backgroundImage: require("@/assets/images/cities/saopablo.jpg"),
          show: false,
        },
        {
          name: "Liliana Lopez",
          role: "teacher-liliana-role",
          university: "university-simon-name",
          profilePicture: require("@/assets/images/profiles/lilianalopez.png"),
          backgroundImage: require("@/assets/images/cities/barranquilla.jpg"),
          show: false,
        },
        {
          name: "Laura Jimena Buitrago",
          role: "teacher-laura-role",
          university: "university-libertadores-name",
          profilePicture: require("@/assets/images/profiles/laurabuitrago.jpg"),
          backgroundImage: require("@/assets/images/cities/bogota.jpg"),
          show: false,
        },
      ],
      administrativePersonal: [
        {
          name: "Nicole Arbelaez ",
          university: "Fundación Universitaria Los Libertadores",
          email: "",
        },
        {
          name: "Romina Molina ",
          university: "Universidad Simón Bolívar",
          email: "",
        },
        {
          name: "Liliana Patricia López Forero",
          university: "Universidad Simón Bolívar",
          email: "",
        },
        {
          name: "Betzi Elena Barrios Alvarino ",
          university: "Universidad Simón Bolívar",
          email: "",
        },
        {
          name: "Yomaira Esther Altahona Caicedo ",
          university: "Universidad Simón Bolívar",
          email: "",
        },
        {
          name: "Ana Cristina Biondo Salomão ",
          university: "Universidad Estatal Paulista",
          email: "",
        },
      ],
      teacherToShow: {},
      visibleTeacher: false,
    };
  },
  methods: {
    showTeacher(teacher) {
      if (teacher == this.teacherToShow) {
        this.closeTeacher();
      } else {
        this.teacherToShow = teacher;
        this.visibleTeacher = true;
      }
    },
    closeTeacher(){
      this.teacherToShow = {};
        this.visibleTeacher = false;
    }
  },
};
</script>
