<template>
  <div>
    <section >
      <v-row justify="center">
        <h1 class="page-title my-10">{{ $t("objectives-menu") }}</h1>
      </v-row>
      <v-parallax class="pt-12" 
        height="400"
        src="@/assets/images/banners/5034772.jpg"
      ></v-parallax>
    </section>
    <section>
      <v-row justify="center">
        <h1 class="primary--text my-10" v-html="$t('objectives-title')"></h1>
      </v-row>
      <v-row justify="center">
        <v-col cols="8">
          <p class=" text-center" v-html="$t('objectives-pharagraph')"></p>
        </v-col>
      </v-row>
    </section>
    <section class="primary">
      <v-row justify="center" class="carousel-div">
        <v-row justify="center">
          <h1
            class="white--text my-10"
            v-html="$t('objective-specific-title')"
          ></h1>
        </v-row>
        <v-carousel
          dark
          cycle
          height="200"
          hide-delimiter-background
          show-arrows-on-hover
        >
          <v-carousel-item v-for="(objective, i) in objectives" :key="i">
            <v-sheet color="primary">
              <v-row class="fill-height" align="center" justify="center">
                <v-col cols="6">
                  <v-card class="white" width="1000">
                    <v-card-text class="objective-carousel-item">
                      " {{ $t(objective.description) }} "
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-row>
    </section>
 </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "Objectives",
  components: {},
  data() {
    return {
      objectives: [
        {
          title: "home-how-work-icebreaker-title",
          description: "objective-1",
        },
        {
          title: "home-how-work-develop-collaborative-activities-title",
          description: "objective-2",
        },
        {
          title: "home-how-work-case-study-title",
          description: "objective-3",
        },
        {
          title: "home-how-work-final-reflection-title",
          description: "objective-4",
        },
      ],
    };
  },
};
</script>
