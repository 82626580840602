<template>
  <v-menu bottom>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <flag
          :iso="currentTranslation.flagIso"
          :title="currentTranslation.displayName"
          :squared="false"
        ></flag>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="translation in translations"
        :key="translation.id"
        @click="change(translation.id)"
      >
        <v-list-item-icon class="mr-2">
          <flag
            :iso="translation.flagIso"
            :title="translation.displayName"
            :squared="false"
          ></flag>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ translation.displayName }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "SwitchLanguage",
  data() {
    return {
      translations: [
        {
          id: "es",
          flagIso: "es",
          displayName: "Español",
        },
        {
          id: "en",
          flagIso: "us",
          displayName: "English",
        },
        {
          id: "br",
          flagIso: "br",
          displayName: "Portugues",
        },
      ],
      currentTranslation: {
        id: "es",
        flagIso: "es",
        displayName: "Español",
      },
    };
  },
  computed: {},
  methods: {
    change(locale) {
      localStorage.locale = locale;
      this.$i18n.locale = locale;
      this.currentTranslation = this.translations.find((x) => x.id == locale);
    },
  },
  created() {
    debugger;
    if (localStorage.locale) {
      this.$i18n.locale = localStorage.locale;
      this.currentTranslation = this.translations.find(
        (x) => x.id == localStorage.locale
      );
    } else {
      localStorage.locale = this.currentTranslation.id;
      this.$i18n.locale = this.currentTranslation.id;
    }
  },
};
</script>
